import styled from 'styled-components';

export const ButtonWhite = styled.button`
  background: ${(props) => props.color || '#00FF33'};
  color: ${(props) => (props.color ? '#000000' : '#000000')};
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  border: ${(props) => (props.color ? '2px solid #000000' : '2px solid #00FF33')};
  border-radius: 6px;
  height: 40px;
  outline: none;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 120px;
  transition: all 1.1s ease 0s;
    &:hover {
      color: ${(props) => (props.color ? '#EBEBEB' : '#000000')};
      background: ${(props) => (props.color ? '#00FF33' : '#EBEBEB')};
      border-color: #000000;
      transition: all 0.4s ease 0s;
    }

  @media only screen and (max-width: 1024px) {
    width: ${(props) => (props.width ? '160px' : '100%')};
  }

  @media only screen and (max-width: 768px) {
    width: ${(props) => (props.width ? '140px' : '100%')};
  }

  @media only screen and (max-width: 480px) {
    width: ${(props) => (props.width ? '130px' : '100%')};
  }
`;
